<template>
    <div>
        <Certifi v-if="ShowCertifi" @closeCertifi="ShowCertifi = false" />
        <div class="mo_space_header">
            <div class="mo_header_box">
                <a>
                    <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                </a>
                <h2>비밀번호 찾기</h2>
                <div></div>
            </div>
        </div>
        <section class="login_main find_pw">
            <div class="login_main_box">
                <h1>비밀번호 찾기</h1>
                <div class="email_input">
                    <div class="email_input_box">
                        <input type="text" placeholder="이메일 ID" v-model="email1" /> @
                        <input type="text" v-model="email2"/>
                    </div>
                    <div class="select-box-area">
                        <div class="label select-box" v-if="email2 === ''" @mouseover.once="clickSelectBox()">
                            직접입력
                        </div>
                        <div class="label select-box" v-else @mouseover.once="clickSelectBox()">
                            {{ emailOption }}
                        </div>
                        <div class="select-box-dropDown">
                            <ul>
                                <li class="optionItem"><p>직접입력</p></li>
                                <li class="optionItem"><p>naver.com</p></li>
                                <li class="optionItem"><p>gmail.com</p></li>
                                <li class="optionItem"><p>hanmail.net</p></li>
                                <li class="optionItem"><p>daum.net</p></li>
                                <li class="optionItem"><p>kakao.com</p></li>
                                <li class="optionItem"><p>nate.com</p></li>
                                <li class="optionItem"><p>yahoo.com</p></li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="select-box-area">
            <select v-model="email2" class="select-box" style="border:none;">
              <option value="" selected>직접입력</option>
              <option><li><p>naver.com</p></li></option>
              <option><li><p>nate.com</p></li></option>
              <option><li><p>gmail.com</p></li></option>
              <option><li><p>kakao.com</p></li></option>
            </select>
          </div> -->
                    <button class="email_input_btn" @click="[emailCheck(), clickCertifi()]">인증요청</button>
                </div>

                <p id="fail_id" v-if="!checkEmail" style="margin-bottom: 0px">
                    등록되지 않은 이메일입니다.
                </p>
                <div class="certificate_number">
                    <input type="text" placeholder="인증번호 4자리 입력" v-model="authCode" />
                    <p class="authCode_timer">{{ TimerStr }}</p>
                </div>
                <button @click="checkAuthCode()">다음</button>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Certifi from "@/components/modal/FindPw/Certifi.vue";
import $ from "jquery";
import axios from "axios";
import router from "@/router";

export default defineComponent({
  data () {
    return {
      ShowCertifi: false,
      checkEmail: true,
      email1: "",
      email2: "",
      authCode: null,
      selectBoxCheck: false,
      emailOption: '직접입력',

      /* 타이머 */
      Timer: null,
      TimeCounter: 300,
      TimerStr: "05:00"
    };
  },
  components: {
    Certifi
  },
  methods: {
    clickPre () {
      history.back();
    },
    startCheckEmail () {
      // 문자발송성공시 호출
      if (this.Timer != null) {
        this.timerStop(this.Timer);
        this.Timer = null;
      }
      this.Timer = this.timerStart();
    },
    timerStart () { // 1초에 한번씩 start 호출
      this.TimeCounter = 300; var interval = setInterval(() => {
        this.TimeCounter--; // 1초씩 감소
        this.TimerStr = this.prettyTime();

        if (this.TimeCounter <= 0) {
          this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },
    timerStop (Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
    },
    prettyTime: function () { // 시간 형식으로 변환 리턴
      var time = this.TimeCounter / 60; var minutes = parseInt(time);
      var secondes = Math.round((time - minutes) * 60);

      return (
        minutes.toString().padStart(2, "0") + ":" + secondes.toString().padStart(2, "0")
      );
    },
    emailCheck () {
      var toMail = this.email1 + "@" + this.email2;
      // console.log(toMail);
      if (this.email1.length > 0 && this.email2.length > 0) { // 이메일 정상 기입
        axios
          .post("/api/user/getEmailCheck", { toMail })
          .then(res => {
            // console.log(res.data.result);
            if (res.data) {
              this.checkEmail = true;
              this.ShowCertifi = true;
              this.startCheckEmail();
            } else {
              this.checkEmail = false;
            }
          })
          .catch(err => {
            if (err) {
              this.checkEmail = false;
            }
            console.log(err);
          });
      } else {
        alert('이메일을 바르게 입력해 주세요!');
        this.email1 = "";
        this.email2 = "";
        document.getElementById("email1").focus();
      }
    },
    checkAuthCode () {
      var authCode = this.authCode;
      var toMail = this.email1 + "@" + this.email2;

      if (this.email1.length !== 0 && this.email2.length !== 0) {
        axios
          .post("/api/user/checkPassEmail", { toMail, authCode })
          .then(res => {
            // console.log(res.data.result);
            if (res.data.result) {
              router.push({ name: "ChangePass", params: { email: toMail, authCode: authCode } });
            } else {
              alert("이메일 인증에 실패했습니다.");
            }
          })
          .catch(err => {
            console.log(err);
            alert("이메일 인증에 실패했습니다.");
          });
      } else {
        alert('이메일을 입력해주세요.');
      }
    },
    clickSelectBox () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          // console.log(lb.parentNode.classList);
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        if (item.textContent !== '직접입력') {
          this.email2 = item.textContent;
        } else {
          this.email2 = "";
        }
        this.emailOption = item.textContent;
        // label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    clickCertifi () {
      $(document).on("click", ".email_input_btn", function () {
        $(this).text("재요청");
      });
    }
  }
});
</script>

<style scoped>
  .certificate_number .authCode_timer {
    color: #ff2d55;
    font-weight: 500;
    position: absolute;
    top: 23%;
    right: 3%;
  }
  .certificate_number input {
    padding-right: 20%;
  }
  @media all and (max-width: 500px) {
    .certificate_number .authCode_timer {
      top: 20%;
    }
  }
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
