import { render, staticRenderFns } from "./FindPass.vue?vue&type=template&id=9af1bae2&scoped=true"
import script from "./FindPass.vue?vue&type=script&lang=js"
export * from "./FindPass.vue?vue&type=script&lang=js"
import style0 from "./FindPass.vue?vue&type=style&index=0&id=9af1bae2&prod&scoped=true&lang=css"
import style1 from "@/assets/css/content2.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@/assets/css/font.css?vue&type=style&index=2&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9af1bae2",
  null
  
)

export default component.exports