<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>이메일로 전송된 인증번호 4자리를 입력해주세요.</h3>
            <div class="single-file-check">
                <p @click="$emit('closeCertifi')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup () {}
});
</script>
